import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { 
  Mobiles, 
  Slider1, 
  Slider2, 
  Slider3, 
  Slider4, 
  SliderOneMobile,
  SliderTwoMobile,
  SliderThreeMobile,
  SliderFourMobile

  // CSMobile
  // AbbeyMobile
} from '../../assets/img';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import '../../slider.css';
import { NavButton } from '../../styles';
import { Link, useNavigate } from 'react-router-dom';
import { Screen } from '../../styles'
// import { AbbeyIcon } from '../../assets/svg';

const SlideTwo = ({ state, wireState }) => {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState(false)
  const [contentMobile, setContentMobile] = useState(false)

  useEffect(() => {
    const contentResponsive = () => {
      return window.innerWidth <= 600 ? setContentMobile(true) : setContentMobile(false)
    }
    const responsive = () => {
      return window.innerWidth <= 900 ? setMobile(true) : setMobile(false)
    }
    responsive()
    contentResponsive()
    window.addEventListener('resize', () => responsive())
    window.addEventListener('resize', () => contentResponsive())

    return () => {
      window.removeEventListener('resize', () => responsive())
      window.removeEventListener('resize', () => contentResponsive())
    }
  }, [])

  return (
    <Carousel
      width={'100%'}
      animationHandler='fade'
      infiniteLoop={true}
      autoPlay={true}
      showIndicators={true}
      showStatus={false}
      showThumbs={false}
    >
      <Box bgImg={`url(${state[0]})`} className='slideOne'>
        {/* <Container> */}
        {/* <ButtonWrapper className='slideOne'> */}
        {/* <Button className='button-0'>
          <Link 
          style={{ color: '#fff', textDecoration: 'none'}} 
          to={'https://linktr.ee/amagm2024'}
          target='_blank'
          >Watch Live</Link>
        </Button> */}
        {/* </ButtonWrapper> */}
        {/* </Container> */}
      </Box>

      <Box bgImg={`url(${state[1]})`} className='slideTwo'></Box>
      <Box bgImg={`url(${state[2]})`} className='slideTwo'></Box>
      <Box bgImg={`url(${state[3]})`} className='slideThree'>
      <Container>
        <ButtonWrapper className='slideThree'>
        <Button onClick={() => navigate('/business/fixed-deposit')} className='button-1'>
              Learn More
            </Button>
        </ButtonWrapper>
        </Container>
      </Box>
      <Box bgImg={`url(${state[4]})`} className='slideFour'>
        <Container>
          <ButtonWrapper className='slideFour'>
        <Button onClick={() => navigate('/personal-form')} className='button-1'>
              Get Started
            </Button>
            <Button onClick={() => navigate('/personal/bloom-kidz')} className='button-2'>
              Learn More
            </Button>
          </ButtonWrapper>
        </Container>
      </Box>
      <Box bgImg={`url(${state[5]})`}>
        <Container>
          {/* <Content className='slideFive'>
            <h2>
              +Join the {mobile ? <><br /><span>Cool</span></> : <span>Cool</span>} Tribe
            </h2>
            <p>Become an Abbey Zoomer</p>
          </Content> */}
          <ButtonWrapper className='slide5'>
            <Button className='button-3'>
              <Link style={{ color: '#fff', textDecoration: 'none'}} to={'https://corporate.abbeymortgagebank.com'}>Get Started</Link>
            </Button>
            {/* <Button
              onClick={() => navigate('/personal/abbey-zoomers')}
              className='button-4'
            >
              Learn More
            </Button> */}
          </ButtonWrapper>
        </Container>
      </Box>
    </Carousel>
  );
};

const MobileSection = styled.div`
  width: 100%;
  min-height: 100vh !important;
  background-color: #f5f5f5;
  ${Screen.miniLargeScreen`
  padding-top: 3rem;
  `}
  ${Screen.pad`
  padding-top: 3rem;
  `}
  ${Screen.mediumpc`
  min-height: 45vh!important;
  padding-top: 3rem;
  `}
  ${Screen.smallpc`
  min-height: 45vh!important;
  margin-top: 5rem;
  padding-top: 3rem;
  `}
  ${Screen.tablet`
  min-height: 45vh!important;
  margin-top: 5rem;
  padding-top: 3rem;
  `}
  ${Screen.surfaceDuo`
  min-height: 50vh!important;
  margin-top: 5rem;
  padding-top: 3rem;
  `}
  ${Screen.mediumPhone`
  min-height: 40vh!important;
  margin-top: 5rem;
  padding-top: 3rem;
  `}
   ${Screen.iphone`
  min-height: 40vh!important;
  margin-top: 5rem;
  padding-top: 2.5rem;
  `}
  ${Screen.phone`
  min-height: 50vh!important;
  margin-top: 5rem;
  padding-top: 3rem;
  `}
${Screen.smallPhone`
  min-height: 50vh!important;
  margin-top: 5rem;
  padding-top: 5rem;
  `}
`;
const Image = styled.img`
&.mobiles {
  /* width: 200px!important;
  height: 200px!important; */
  ${Screen.miniLargeScreen`
 width: 30rem!important;
  margin-left: 3rem
`}
  ${Screen.pad`
 width: 30rem!important;
  margin-left: 3rem
`}
  ${Screen.mediumpc`
 width: 30rem!important;
  margin-left: 3rem
`}
  ${Screen.mediumpc`
 width: 30rem!important;
  margin-left: 3rem
`}
  ${Screen.smallpc`
 width: 30rem!important;
  margin-left: 3rem
`}
  ${Screen.tablet`
 width: 25rem!important;
  margin-left: 3rem
`}
  ${Screen.surfaceDuo`
 width: 17rem!important;
  margin-left: 5rem
  `}
  ${Screen.mediumPhone`
  width: 17rem!important;
  margin-left: 5rem
  `}
    ${Screen.iphone`
  width: 15rem!important;
  margin-left: 5rem
  `}
  ${Screen.phone`
  width: 14rem!important;
  margin-left: 5rem
  `}
  ${Screen.smallPhone`
  width: 10rem!important;
  margin-left: 5rem
  `}
}
&.abbey {
  width: 100%;
}
`;
const FlexBox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const Logo = styled.img`
width: 1.5rem!important;
padding-bottom: 0;
${Screen.smallpc`
display: none!important  
`}
${Screen.tablet`
margin-left: -5rem;  
display: inline!important  
`}
${Screen.surfaceDuo`
margin-left: -5rem;    
`}
${Screen.mediumPhone`
margin-left: -3.5rem;
`}
${Screen.phone`
margin-left: -3.5rem;
`}
${Screen.smallPhone`
margin-left: -3.5rem!important;
// display: none!important
`}
`;
const AbbeyLogoWrapper = styled.div`
/* width: 50%; */
`
const Content = styled.div`
  /* padding-right: 5rem; */
  &.slideOne {
    text-align: left;

    h2 {
      color: #002668;
      font-size: 3rem;
      padding-left: 5rem;
      text-shadow: 3px 1px 3px #666;

      span {
        color: #c81e19;
      }

      ${Screen.pad`
      font-size: 2.5rem;
      `}
      ${Screen.mediumpc`
       font-size: 2.3rem;
      `}
      ${Screen.smallpc`
       font-size: 2.5rem;
       padding-left: 0;
      `}
      ${Screen.tablet`
       font-size: 2rem;
      `}
      ${Screen.miniTablet`
       font-size: 1.8rem;
      `}
       ${Screen.largePhone`
       font-size: 1.5rem;
      `}
       ${Screen.smallPhone`
        font-size: 1.3rem;
      `}
    }
    p {
      color: #002668;
      padding-top: 1.5rem;
      font-size: 1.5rem;
      padding-left: 5rem;

      ${Screen.pad`
      font-size: 1.2rem;
      `}
      ${Screen.mediumpc`
       font-size: 1.1rem;
      `}
      ${Screen.smallpc`
       font-size: 1.2rem;
       padding-left: 0;
       padding-top: 1rem
      `}
      ${Screen.tablet`
       font-size: 1rem;
       `}
       ${Screen.largePhone`
       font-size: .9rem;
      `}
       ${Screen.smallPhone`
        font-size: .8rem;
        padding-top: .5rem;
      `}
      
    }
  }

  &.slideTwo {
    /* width: 50%; */
    padding-right: 3rem;
    ${Screen.mediumpc`
      padding-right: 5rem;
    `}
    ${Screen.smallpc`
      padding-right: 5rem;
    `}
    ${Screen.tablet`
      padding-right: 3rem;
    `}
    ${Screen.surfaceDuo`
      padding-right: 6rem;
    `}
    ${Screen.mediumPhone`
      padding-right: 6rem;
    `}
    ${Screen.phone`
      padding-right: 5rem;
    `}
    ${Screen.smallPhone`
      padding-right: 5rem;
    `}
    

    article {
      font-size: 3rem;
      font-weight: bold;
      margin-top: 7rem;
      text-align: right;
      color: #002668;
      padding-right: 2rem;
      ${Screen.pad`
      font-size: 2.5rem;
      margin-top: 0;
      padding-top: 3rem;
    `}
      ${Screen.mediumpc`
      font-size: 2.1rem;
      margin-top: 0;
      padding-top: 3rem;
    `}
      ${Screen.smallpc`
      font-size: 1.8rem;
      margin-top: 0;
      padding-top: 3rem;
    `}
      ${Screen.tablet`
      font-size: 1.8rem;
      margin-top: 0;
      padding-top: 3rem;
    `}
      ${Screen.surfaceDuo`
      font-size: 1.5rem;
      margin-top: 0;
      padding-top: 3rem;
    `}
      ${Screen.mediumPhone`
      font-size: 1rem;
      margin-top: 0;
      padding-top: 3rem;
      `}
      ${Screen.phone`
      font-size: 1rem;
      margin-top: 0;
      padding-top: 3rem;
      `}
      ${Screen.smallPhone`
      font-size: 1rem;
      margin-top: 0;
      padding-top: 3rem;
      `}
    }
    h2 {
      font-size: 6rem;
      padding-top: 4rem;
      margin-top: -2rem;
      color: #002668;
      padding-right: 2rem;
      ${Screen.pad`
      font-size: 4rem;
      padding-top: 3rem;
      `}
      ${Screen.mediumpc`
      font-size: 3.3rem;
      padding-top: 3rem;
      `}
      ${Screen.smallpc`
      font-size: 3rem;
      padding-top: 1.8rem;
      `}
      ${Screen.tablet`
      font-size: 3rem;
      padding-top: 1.8rem;
      `}
      ${Screen.surfaceDuo`
      font-size: 2rem;
      padding-top: 1.8rem;
      `}
      ${Screen.mediumPhone`
      font-size: 1.5rem;
      padding-top: 1.8rem;
      `}
      ${Screen.smallPhone`
      font-size: 1.5rem;
      padding-top: 1.8rem;
      `}
       ${Screen.phone`
      font-size: 1.5rem;
      padding-top: 1.8rem;
      `}

      span {
        color: #c81e19;
      }
    }
  }
  &.slideThree, &.slideSix {
    text-align: left;
    padding-top: 1rem;

    h2 {
      color: #002668;
      font-size: 3rem;
      padding-left: 5rem;
      /* text-align: left; */

      span {
        color: #c81e19;
      }
     
      ${Screen.pad`
      font-size: 2.5rem;
  `}
      ${Screen.mediumpc`
       font-size: 2.3rem;
      `}
      ${Screen.smallpc`
       font-size: 2.5rem;
       padding-left: 0;
      `}
      ${Screen.tablet`
       font-size: 2rem;
      `}
      ${Screen.miniTablet`
       font-size: 1.8rem;
      `}
       ${Screen.largePhone`
       font-size: 1.5rem;
      `}
       ${Screen.smallPhone`
        font-size: 1.3rem;
      `}
    }
    p {
      color: #002668;
      padding-top: 1.5rem;
      font-size: 1.3rem;
      padding-left: 5rem;
      ${Screen.pad`
      font-size: 1.2rem;
  `}
      ${Screen.mediumpc`
       font-size: 1.1rem;
      `}
      ${Screen.smallpc`
       font-size: 1.2rem;
       padding-left: 0;
       padding-top: 1rem
      `}
      ${Screen.tablet`
       font-size: 1rem;
       `}
       ${Screen.largePhone`
       font-size: .9rem;
      `}
       ${Screen.smallPhone`
        font-size: .8rem;
        padding-top: .5rem;
      `}
      
    }
  }

  &.slideFour {
    text-align: left;
    /* padding-top: 3rem; */

    h2 {
      color: #002668;
      font-size: 3rem;
      padding-left: 5rem;
      ${Screen.pad`
      font-size: 2.5rem;
      `}
      ${Screen.mediumpc`
       font-size: 2.3rem;
      `}
      ${Screen.smallpc`
       font-size: 2.5rem;
       padding-left: 0;
      `}
      ${Screen.tablet`
       font-size: 2rem;
      `}
      ${Screen.miniTablet`
       font-size: 1.8rem;
      `}
       ${Screen.largePhone`
       font-size: 1.5rem;
      `}
       ${Screen.smallPhone`
        font-size: 1.3rem;
      `}
      /* text-align: left; */

      span {
        color: #c81e19;
      }
    }
    p {
      color: #002668;
      padding-top: 1.5rem;
      font-size: 1.3rem;
      padding-left: 5rem;
      ${Screen.pad`
      font-size: 1.2rem;
      `}
      ${Screen.mediumpc`
       font-size: 1.1rem;
      `}
      ${Screen.smallpc`
       font-size: 1.2rem;
       padding-left: 0;
       padding-top: 1rem
      `}
      ${Screen.tablet`
       font-size: 1rem;
       `}
       ${Screen.largePhone`
       font-size: .9rem;
      `}
       ${Screen.smallPhone`
        font-size: .8rem;
        padding-top: .5rem;
      `}
    }
  }

  &.slideFive {
    text-align: right;
    padding-top: 1rem;
    padding-right: 2rem;
    ${Screen.smallpc`
    padding-right: 0!important;
    `}

    h2 {
      color: #002668;
      font-size: 3rem;
      padding-left: 5rem;
      ${Screen.pad`
      font-size: 2.5rem;
      `}
      ${Screen.mediumpc`
       font-size: 2.3rem;
      `}
      ${Screen.smallpc`
       font-size: 2.5rem;
       padding-left: 0;
      `}
      ${Screen.tablet`
       font-size: 2rem;
      `}
      ${Screen.miniTablet`
       font-size: 1.8rem;
      `}
       ${Screen.largePhone`
       font-size: 1.5rem;
      `}
       ${Screen.smallPhone`
        font-size: 1.3rem;
      `}
      /* padding-right: 2rem; */

      span {
        color: #c81e19;
      }
    }
    p {
      color: #002668;
      padding-top: 1.5rem;
      font-size: 1.3rem;
      ${Screen.pad`
      font-size: 1.2rem;
      `}
      ${Screen.mediumpc`
       font-size: 1.1rem;
      `}
      ${Screen.smallpc`
       font-size: 1.2rem;
       padding-left: 0;
       padding-top: 1rem
      `}
      ${Screen.tablet`
       font-size: 1rem;
       `}
       ${Screen.largePhone`
       font-size: .9rem;
      `}
       ${Screen.smallPhone`
        font-size: .8rem;
        padding-top: .5rem;
      `}
      /* padding-right: 2rem; */
    }
  }

  &.slideSix {
    padding-top: 9rem;
    ${Screen.miniLargeScreen`
      padding-top: 10rem;
    `}
    ${Screen.mediumpc`
      padding-top: 0;
    `}
    ${Screen.tablet`
      padding-top: 5rem;
    `}
    ${Screen.surfaceDuo`
      padding-top: 0;
      text-align: center;
    `}
    ${Screen.mediumPhone`
      padding-top: 0;
      text-align: center;
    `}
    ${Screen.phone`
      padding-top: 0;
      text-align: center;
    `}
    ${Screen.smallPhone`
    padding-top: 0;
    text-align: center;
    `}
    p {
      padding-left: 1.5rem;
      font-weight: 600

      ${Screen.mediumPhone`
      padding-left: 0;
      `}
    }
  }
`;


const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  padding-top: 15rem;
  background-image: ${({ bgImg }) => bgImg};
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  ${Screen.miniLargeScreen`
  padding-top: 15rem;
  // min-height: 70vh!important
  `}
  ${Screen.pad`
  padding-top: 10rem;
  min-height: 50vh!important;
  `}
  ${Screen.mediumpc`
  min-height: 40vh;
  padding-top: 15rem;
  `}
  ${Screen.smallpc`
  min-height: 40vh;
  margin-top: 5rem;
  padding-top: 10rem;
  `}
   ${Screen.tablet`
  min-height: 40vh;
  margin-top: 5rem;
  padding-top: 10rem;
  `}
   ${Screen.surfaceDuo`
  min-height: 50vh;
  margin-top: 5rem;
  padding: 7rem 0 3rem;
  `}
  ${Screen.mediumPhone`
  min-height: 50vh;
  margin-top: 5rem;
  // padding: 7rem 0 3rem;
  background-size: 100% 100%;
  `}
   ${Screen.iphone`
  min-height: 40vh;
  margin-top: 5rem;
  background-size: 100% 100%;
  // padding: 6rem 0 3rem;
  // background-size: 100% contain;
  `}
  ${Screen.phone`
  min-height: 40vh;
  margin-top: 5rem;
  // padding: 6rem 0 3rem;
  `}
  ${Screen.smallPhone`
  min-height: 50vh;
  margin-top: 5rem;
  // padding: 6rem 0 3rem;
  background-size: cover;

  // &.slideOne {
  //   min-height: 30vh;
  // }

  `}
`;

const Button = styled(NavButton)`
  width: 7rem;
  height: 2.5rem;
  border-radius: 5px;
  margin-left: ${({ ml }) => ml};
  margin-top: 2rem;
  letter-spacing: 1px;
  background-color: #c81e19;
  // box-shadow: 5px 3px 5px #ccc;
  margin-right: ${({ mr }) => mr};

  &.button-1, &.button-2, &.button-6 {
  ${Screen.smallpc`
  width: 6rem;
  height: 2rem;
  letter-spacing: 0;
  margin-left: 0;
  margin-top: 1rem,
  box-shadow: none;
  `}
  ${Screen.mediumPhone`
  width: max-content;
  height: 2rem;
  letter-spacing: 0;
  margin-left: 20px;
  margin-top: 0;
  box-shadow: none;
  padding: 0 10px;
  `}
  ${Screen.iphone`
  width: max-content;
  height: 2rem;
  letter-spacing: 0;
  margin-left: 0;
  margin-top: 10px;
  box-shadow: none;
  padding: 0 10px;
  `}
  ${Screen.phone`
  width: max-content;
  height: 2rem;
  letter-spacing: 0;
  margin-left: 10px;
  margin-top: 0;
  box-shadow: none;
  padding: 0 10px;
  `}
  ${Screen.smallPhone`
  width: max-content;
  height: 2rem;
  letter-spacing: 0;
  margin-left: 10px;
  margin-top: 0;
  box-shadow: none;
  padding: 0 10px;
  `}
  }
  &.button-2 {
    ${Screen.smallpc`
    margin-left: 1rem;
    `};
  }
  &.button-3, &.button-4 {
    ${Screen.smallpc`
    width: 6rem;
    height: 2rem;
    letter-spacing: 0;
    // margin-right: 0!important;
    margin-top: 3rem;
    box-shadow: none
    `};
  }
  &.button-4 {
    ${Screen.smallpc`
    margin-left: .5rem!important;
    `}
  }
  &.button-6 {
      ${Screen.ipadAir`
        margin-left: 1.5rem;
        margin-top: 1rem;
      `}
      ${Screen.mediumPhone`
        margin-left: 1.5rem
      `}
      ${Screen.phone`
        margin-left: 1.5rem
      `}
      ${Screen.smallPhone`
        margin-left: 1.5rem
      `}
    }

    // &.button-0 {
    //   ${Screen.phone`
    //   padding-top: 3rem;
    //   `}
    // }
`

const Container = styled.div`
  width: 90%;
  margin: 0 auto;

  &.slideSix {
    ${Screen.mediumpc`
    margin-top: 6rem;
    `}
    ${Screen.smallpc`
    margin-top: 5rem;
    `}
    ${Screen.tablet`
    margin-top: 0;
    `}
    ${Screen.surfaceDuo`
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center
    `}
    ${Screen.mediumPhone`
      margin-top: 4rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center
    `}
     ${Screen.iphone`
      margin-top: 5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center
    `}
    ${Screen.phone`
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center
    `}
    ${Screen.smallPhone`
      margin-top: 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center
    `}
    ${Screen.galaxyFold`
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center
    `}
  }
`;

const ButtonWrapper = styled.div`
display: flex;
justify-content: flex-start;
margin-top: 16rem;

&.slideThree {
  margin-top: 13rem;
}
&.slideFour {
  margin-top: 16rem;
  margin-left: 10px
}

&.slide5 {
  margin-top: 12rem;
  margin-left: 10px
}

${Screen.miniLargeScreen`
margin-top: 15rem!important;
padding-bottom: 5rem!important;
&.slideFour {
  margin-top: 20rem!important
}
`}
${Screen.mediumpc`
&.slideOne {
  margin-top: 10rem!important;
}
`}
${Screen.pad`
margin-top: 15rem!important;
padding-bottom: 3rem!important;
&.slideFour {
  margin-top: 20rem!important
}
`}
${Screen.smallpc`
margin-top: 15rem!important;
margin-left: 10px!important;
padding-bottom: 0;

&.slideFour {
  margin-top: 16rem!important
}


`}
${Screen.tablet`
margin-top: 10rem!important;
margin-left: 10px!important;
`}

${Screen.surfaceDuo`
&.slideOne {
  display: flex;
  justify-content: center;
}
`}
${Screen.mediumPhone`

&.slideFour {
  margin-top: 14rem!important;
  margin-left: 0;
}

&.slideThree {
  margin-top: 13rem!important;
  margin-left: 0;
}

&.slide5 {
  margin-top: 10rem!important;
  margin-left: -1rem!important;
}
`}

${Screen.phone`
margin-top: 10rem!important;

`}
`

export default SlideTwo;